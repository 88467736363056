<template>
  <div id="app">
    <!-- Loader -->

    <!-- Start Navbar -->
    <nav class="navbar navbar-expand-lg fixed-top custom-nav sticky">
      <div class="container">
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="mdi mdi-menu"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarCollapse">
          <a
            href="https://etherscan.io/address/0x8d10e32e677ee75acf89b950cfc625bae4140b33"
            target="_blank"
            ><img
              class="fa fa-etherscan"
              src="css/etherscan-logo-light-circle.svg"
              alt=""
          /></a>
          <a
            href="https://opensea.io/collection/shogunstigerdojoo"
            target="_blank"
            ><img
              class="fa fa-opensea"
              src="css/Logomark-Transparent White.svg"
              alt=""
          /></a>
          <a href="http://discord.gg/tMRkfGQFuF" target="_blank"
            ><i class="fab fa-discord"></i
          ></a>
          <a
            href="https://twitter.com/ShogunTigerDojo"
            class="fa fa-twitter"
            target="_blank"
          ></a>
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link" href="#home">Home</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#about">About</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#tiger">Tigers</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#road">Road Map</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#faq">Faq</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#team">Team</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- End Navbar -->

    <!-- START HOME -->
    <section class="section header-bg-img h-100vh" id="home">
      <div class="bg-overlay"></div>
      <div class="header-table">
        <div class="header-table-center">
          <div class="container">
            <div class="row justify-content-center align-items-center">
              <div class="col-md-12 text-center">
                <div class="quantity" style="padding-bottom: 30px;">
                  <a
                    @click="incrementValueks()"
                    class="quantity__minus"
                    style="color: white;"
                    ><span>-</span></a
                  >
                  <input
                    name="quantity"
                    type="text"
                    class="quantity__input"
                    @input="mintcount = $event.target.value"
                    :value="mintcount"
                  />
                  <a
                    @click="incrementValue()"
                    class="quantity__plus"
                    style="color: white;"
                    ><span>+</span></a
                  >
                </div>
                <div>
                  <p data-aos="fade-up" data-aos-delay="100">
                    <a
                      @click="buyTiger()"
                      class="btn btn-danger btn-md smoothscroll overflow-auto"
                      style="color: white;"
                      >Mint Tiger !! ({{ mintotaleth() }} ETH)</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END HOME -->

    <!-- START ABOUT -->
    <section class="section bg-light" id="about">
      <div class="container">
        <div class="row vertical-content">
          <div class="col-lg-6">
            <div class="about-img mt-3">
              <div
                class="inner-img"
                data-wow-delay="0ms"
                data-wow-duration="1500ms"
              >
                <div class="image">
                  <img
                    src="images/gif.gif"
                    alt=""
                    class="img-fluid mx-auto d-block"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="about-desc mt-3">
              <div
                style="text-align: justify"
                class="inner-desc section_title about-titel"
              >
                <h2 class="title_1 mb-0 text-uppercase text-white">
                  <span class="font-weight-bold"> Welcome to </span>Shogun's
                  Tiger Dojo
                </h2>

                <p class="text-muted mt-4">
                  Become part of something special and do a little good in the
                  world at the same time.
                </p>
                <p class="text-muted mt-2">
                  Tigers are a collection of 10,000 randomly generated digital
                  collectibles each coming with its own unique traits and
                  personality.
                </p>
                <p class="text-muted mt-2">
                  Your tiger doubles as your membership token which gets you
                  access into the tiger community where you can meet like-minded
                  NFT enthusiasts and get access to giveaways and special prizes
                  only tigers can get.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END ABOUT -->
    <section class="section text-center" id="tiger">
      <div class="container">
        <div style="padding: 10px" class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center text-white">
              <h2>The<span class="font-weight-bold"> Tigers</span></h2>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row work-filter">
          <div
            class="col-lg-4 work_item graphic design"
            style="position: absolute; left: 0px; top: 0px"
          >
            <a href="images/works/1.png" class="img-zoom">
              <div class="work_box">
                <div class="work_img">
                  <img
                    src="images/works/1.png"
                    alt="image"
                    class="img-fluid mx-auto d-block"
                  />
                </div>
              </div>
            </a>
          </div>
          <div
            class="col-lg-4 work_item graphic design seo"
            style="position: absolute; left: 0px; top: 0px"
          >
            <a href="images/works/2.png" class="img-zoom">
              <div class="work_box">
                <div class="work_img">
                  <img
                    src="images/works/2.png"
                    alt="image"
                    class="img-fluid mx-auto d-block"
                  />
                </div>
              </div>
            </a>
          </div>
          <div
            class="col-lg-4 work_item graphic wordpress"
            style="position: absolute; left: 0px; top: 0px"
          >
            <a href="images/works/3.png" class="img-zoom">
              <div class="work_box">
                <div class="work_img">
                  <img
                    src="images/works/3.png"
                    alt="image"
                    class="img-fluid mx-auto d-block"
                  />
                </div>
              </div>
            </a>
          </div>
          <div
            class="col-lg-4 work_item wordpress logo"
            style="position: absolute; left: 0px; top: 0px"
          >
            <a href="images/works/4.png" class="img-zoom">
              <div class="work_box">
                <div class="work_img">
                  <img
                    src="images/works/4.png"
                    alt="image"
                    class="img-fluid mx-auto d-block"
                  />
                </div>
              </div>
            </a>
          </div>
          <div
            class="col-lg-4 work_item seo design"
            style="position: absolute; left: 0px; top: 0px"
          >
            <a href="images/works/5.png" class="img-zoom">
              <div class="work_box">
                <div class="work_img">
                  <img
                    src="images/works/5.png"
                    alt="image"
                    class="img-fluid mx-auto d-block"
                  />
                </div>
              </div>
            </a>
          </div>
          <div
            class="col-lg-4 work_item wordpress logo"
            style="position: absolute; left: 0px; top: 0px"
          >
            <a href="images/works/6.png" class="img-zoom">
              <div class="work_box">
                <div class="work_img">
                  <img
                    src="images/works/6.png"
                    alt="image"
                    class="img-fluid mx-auto d-block"
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
    <!-- START SERVICES -->
    <section class="section bg-light" id="services">
      <div style="margin-bottom: 60px" class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center">
              <h2 class="text-white">
                Fair <span class="font-weight-bold">Distribution</span>
              </h2>
              <div class="bar"></div>
              <p class="text-muted mx-auto section-subtitle mt-3">
                There is no bonding curve on tigers meaning that everybody pays
                the same mint price and the price will not rise.
              </p>
              <p class="text-muted mx-auto section-subtitle mt-3">
                Buy your tiger
              </p>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-4">
            <div class="box mt-3">
              <div class="text">
                <div>
                  <h5>Programmatically Generated</h5>
                  <p class="mt-3 text-muted">
                    Each tiger is programmatically generated with the follwoing
                    traits hat , eyes, clothes, mouth, skin, background, earring
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="box mt-3">
              <div class="text">
                <div>
                  <h5>Total Supply</h5>
                  <p class="mt-3 text-muted">
                    10,000 tigers are available on release day chosen at random
                    from more than a possible 375.000 combinations
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="box mt-3">
              <div class="text">
                <div>
                  <h5>Mint Price</h5>
                  <p class="mt-3 text-muted">
                    The mintage price is just 0.05 ETH plus gas for each Tiger
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="box mt-3">
              <div class="text">
                <div>
                  <h5>Roadmap</h5>
                  <p class="mt-3 text-muted">
                    We are not just about to drop something without a future,
                    check out our roadmap for our plans going forward after drop
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="box mt-3">
              <div class="text">
                <div>
                  <h5>Equal distribution</h5>
                  <p class="mt-3 text-muted">
                    There is no bonding curve every tiger costs 0.05 plus gas
                    and everyone pays the same mint fee
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="box mt-3">
              <div class="text">
                <div>
                  <h5>Copyright transferred</h5>
                  <p class="mt-3 text-muted">
                    When you mint a tiger you own it that includes ownership
                    rights meaning you can print it on a T-shirt or whatever
                    else tickles your fancy
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END SERVICES -->

    <!-- START CTA -->
    <section style="margin-top: 50px; margin-bottom: 100px" class="section">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center text-white mt-3">
              <h1 style="color: #fac42f">Welcome to the tiger community</h1>
              <h5>
                Minting a tiger gives you access to a community of like-minded
                people; this can give special perks ranging from members-only
                competitions and giveaways to help the very creature that
                inspired our artwork Tigers. We plan to give back to tigers by
                donating to the big cat foundation and adopting our own tiger
                from WWF (see roadmap).
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END CTA -->

    <!-- START CLIENT -->
    <section class="section bg-light" id="client">
      <div style="margin-top: 50px; margin-bottom: 100px" class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center text-white">
              <h2>Community<span class="font-weight-bold"> Tools</span></h2>
              <div class="bar"></div>
              <p class="text-muted">
                Every tiger owner will want to know just how rare their mint is
                that’s why we will be listed on Rarity.tools once we reach a
                certain number of tigers minted, further updates on rarity will
                then be done as more tigers are minted. This is so no one has an
                edge over the others before mint. Discord – Our discord channel
                is where we all hang out, do giveaways and communicate
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END CLIENT -->

    <!--START WORK -->

    <!--END WORK -->

    <!-- START BLOG -->
    <section class="section" id="road">
      <div style="margin-top: 50px; margin-bottom: 100px" class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center text-white">
              <h2>Road<span class="font-weight-bold"> Map</span></h2>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-4">
            <div class="blog_cards mt-3">
              <div class="blog_card blog_card-6">
                <div class="blog_card_info-hover">
                  <div class="blog_card_clock-info"></div>
                </div>
                <div class="blog_card_img"></div>
                <a class="blog_card_link">
                  <div class="blog_card_img-hover"></div>
                </a>
                <div class="blog_card_info">
                  <h5 class="blog_card_title">
                    <a class="text-white font-weight-bold">10% Sold </a>
                  </h5>

                  <p class="mt-3 text-muted">
                    1 Tiger will be chosen at random to receive 0.5 ETH. 10
                    tigers will be airdropped to random holders.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="blog_cards mt-3">
              <div class="blog_card blog_card-7">
                <div class="blog_card_info-hover">
                  <div class="blog_card_clock-info"></div>
                </div>
                <div class="blog_card_img"></div>
                <a class="blog_card_link">
                  <div class="blog_card_img-hover"></div>
                </a>
                <div class="blog_card_info">
                  <h5 class="blog_card_title">
                    <a class="text-white font-weight-bold">25% Sold </a>
                  </h5>

                  <p class="mt-3 text-muted">
                    3 Tigers will be chosen at random to receive 0.5 ETH. 25
                    tigers will be airdropped to random holders.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="blog_cards mt-3">
              <div class="blog_card blog_card-8">
                <div class="blog_card_info-hover">
                  <div class="blog_card_clock-info"></div>
                </div>
                <div class="blog_card_img"></div>
                <a class="blog_card_link">
                  <div class="blog_card_img-hover"></div>
                </a>
                <div class="blog_card_info">
                  <h5 class="blog_card_title">
                    <a class="text-white font-weight-bold">40% Sold </a>
                  </h5>

                  <p class="mt-3 text-muted">
                    4 Tigers will be chosen at random to receive 0.5 ETH. 40
                    tigers will be airdropped to random holders
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-4">
            <div class="blog_cards mt-3">
              <div class="blog_card blog_card-9">
                <div class="blog_card_info-hover">
                  <div class="blog_card_clock-info"></div>
                </div>
                <div class="blog_card_img"></div>
                <a class="blog_card_link">
                  <div class="blog_card_img-hover"></div>
                </a>
                <div class="blog_card_info">
                  <h5 class="blog_card_title">
                    <a class="text-white font-weight-bold">50% Sold </a>
                  </h5>

                  <p class="mt-3 text-muted">
                    5 Tigers will be chosen at random to receive 0.5 ETH. 50
                    tigers will be airdropped to random holders. All pre-sale
                    owners will have a Tiger airdropped to them to thank them
                    for their dedication to the project. Tiger Merchandise will
                    be released to get your Tigers on Hoodies, Caps, Mugs,
                    whatever tickles your fancy really!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="blog_cards mt-3">
              <div class="blog_card blog_card-10">
                <div class="blog_card_info-hover">
                  <div class="blog_card_clock-info"></div>
                </div>
                <div class="blog_card_img"></div>
                <a class="blog_card_link">
                  <div class="blog_card_img-hover"></div>
                </a>
                <div class="blog_card_info">
                  <h5 class="blog_card_title">
                    <a class="text-white font-weight-bold">75% Sold </a>
                  </h5>

                  <p class="mt-3 text-muted">
                    8 Tigers will be chosen at random to receive 0.5 ETH. 80
                    tigers will be airdropped to random holders. 5 ETH will be
                    given to Big Cat Rescue (bigcatrescue.org) 5 ETH will be
                    given to Born Free (bornfree.org.uk)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="blog_cards mt-3">
              <div class="blog_card blog_card-10">
                <div class="blog_card_info-hover">
                  <div class="blog_card_clock-info"></div>
                </div>
                <div class="blog_card_img"></div>
                <a class="blog_card_link">
                  <div class="blog_card_img-hover"></div>
                </a>
                <div class="blog_card_info">
                  <h5 class="blog_card_title">
                    <a class="text-white font-weight-bold">100% Sold </a>
                  </h5>

                  <p class="mt-3 text-muted">
                    4% of total sales and 50% of royalties from OpenSea will be
                    used to sweep the floor. Tiger owners will be able to mint
                    baby tigers for free. Tigers owners pay gas fees. A game
                    development will start. Additionally we will set up funds to
                    support talented independent artists.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END BLOG -->

    <!-- START CONTACT -->
    <section class="section bg-light" id="faq">
      <div style="margin-top: 50px; margin-bottom: 100px" class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center text-white">
              <h2>FAQ<span class="font-weight-bold"> </span></h2>
              <div class="bar"></div>
              <p>Shogun’s Tiger Dojo NFT’s a good investment?</p>
              <p class="text-muted">
                That is ultimately a decision for you to make. We believe
                Shogun’s Tiger Dojo NFT have a long life ahead of them, and will
                be an ever growing and evolving project. However, the success of
                Shogun’s Tiger Dojo NFT relies on so many factors and variables,
                no one knows! Hopefully, Shogun’s Tiger Dojo goes to the moon,
                but like anything in life, don’t spend money you can’t afford to
                lose.
              </p>

              <p>Whats The Future of Shogun’s Tiger Dojo?</p>
              <p class="text-muted">
                The Shogun’s Tiger Dojo NFTs are much more than an NFT art
                project. We have plans and hopes to build an Shogun’s Tiger Dojo
                community that offers long-term utility and a future, community
                rewards and growth, collaboration with brands, and much more! We
                want the community to help dictate and determine which features
                we should be focusing on next - so you guys will be heavily
                involved in the future of the Shogun’s Tiger Dojo project!
              </p>
              <p>Where does my NFT go after I purchase a Tiger?</p>
              <p class="text-muted">
                Your Shogun’s Tiger Dojo NFT will appear in whatever wallet
                address, or connected wallet you used to purchase the Tiger,
                such as Metamask for example. You can view Tigers on Opensea
                when your logged in to your wallet too.
              </p>

              <p>What can I do with my Tiger?</p>
              <p class="text-muted">
                You are free to do anything with them under a non-exclusive
                license meaning you own the rights to your Tiger unless you sell
                it in that case you transfer the rights to the new owner.
              </p>
              <p>This sounds awesome, how do I get involved?</p>
              <p class="text-muted">
                Head over to our Discord, and get envolved with the tiger family
                we cant wait to meet you!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END CONTACT -->
    <section class="section" id="team">
      <div style="margin-top: 50px; margin-bottom: 100px" class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="text-center text-white">
              <h2>Team<span class="font-weight-bold"> Members</span></h2>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-lg-4">
            <div class="blog_cards mt-3">
              <div class="blog_card blog_card-1">
                <div class="blog_card_info-hover">
                  <div class="blog_card_clock-info"></div>
                </div>
                <div class="blog_card_img"></div>
                <a class="blog_card_link">
                  <div class="blog_card_img-hover"></div>
                </a>
                <div class="blog_card_info">
                  <h5 class="blog_card_title">
                    <a class="text-white font-weight-bold">Scarface Tiger</a>
                  </h5>
                  <p class="blog_card_title">
                    <a class="text-white">Creator</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="blog_cards mt-3">
              <div class="blog_card blog_card-4">
                <div class="blog_card_info-hover">
                  <div class="blog_card_clock-info"></div>
                </div>
                <div class="blog_card_img"></div>
                <a class="blog_card_link">
                  <div class="blog_card_img-hover"></div>
                </a>
                <div class="blog_card_info">
                  <h5 class="blog_card_title">
                    <a class="text-white font-weight-bold">Scary Tiger</a>
                  </h5>
                  <p class="blog_card_title">
                    <a class="text-white">Artist</a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="blog_cards mt-3">
              <div class="blog_card blog_card-2">
                <div class="blog_card_info-hover">
                  <div class="blog_card_clock-info"></div>
                </div>
                <div class="blog_card_img"></div>
                <a class="blog_card_link">
                  <div class="blog_card_img-hover"></div>
                </a>
                <div class="blog_card_info">
                  <h5 class="blog_card_title">
                    <a class="text-white font-weight-bold">Kenji Tiger </a>
                  </h5>
                  <p class="blog_card_title">
                    <a class="text-white">Blockchain Developer</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--START FOOTER-->
    <footer class="footer bg-light">
      <div class="container">
        <div class="row justify-content-center text-center">
          <div class="col-md-12">
            <div class="text-center text-white footer-alt">
              <p class="text-muted mb-0">2021 &copy; Shogun's Tiger Dojo.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!--END FOOTER-->

    <!-- Style switcher -->

    <!-- end Style switcher -->

    <!-- BACK TO TOP -->
    <a href="#" class="back_top"> <i class="mdi mdi-chevron-up"> </i> </a>
  </div>
</template>

<script>
import getWeb3 from "../contracts/web3";
//import metamask from "../contracts/metamask";
import contractAbi from "../contracts/abi";

const contractAddress = "0x8D10e32e677Ee75AcF89B950CFc625bae4140B33";
export default {
  name: "App",
  data() {
    return {
      wallet: false,
      test11: null,
      mintcount: 1,
      web3: null,
      account: null,
      contractInstance: null,
      gene: null,
      matron: null,
      sire: null,
      vipers: [],
      isLoading: false,
    };
  },
  mounted() {
    getWeb3().then(res => {
      this.web3 = res;
      this.contractInstance = new this.web3.eth.Contract(
        contractAbi,
        contractAddress
      );
      this.web3.eth
        .getAccounts()
        .then(accounts => {
          [this.account] = accounts;
        })
        .catch(err => {
          console.log(err, "err!!");
        });
    });
  },
  components: {
    // HelloWorld,
  },
  methods: {

    thanks() {
      alert("Thank you so much! SOLD OUT !!");
    },
    mintotaleth() {
      const eth = this.mintcount * 0.05;
      var streth = eth.toString();
      console.log(streth);
      if (streth.length > 4) {
        console.log(streth);
        const newstr = streth.slice(0, 4);
        console.log(newstr);
        streth = newstr;
      }
      return streth; //eth.toString();
    },
    incrementValue() {
      if (this.mintcount < 20) {
        this.mintcount++;
      }
    },
    incrementValueks() {
      if (this.mintcount > 1) {
        this.mintcount--;
      }
    },
    buyTiger() {
      if (this.mintcount < 21) {
        this.isLoading = true;
        this.contractInstance.methods
          .mintTiger(this.account, this.mintcount)
          .send({
            from: this.account,
            value: this.web3.utils.toWei(this.mintotaleth(), "ether"),
          })
          .then(receipt => {
            console.log("recipt" + receipt);
            this.isLoading = false;
          })
          .catch(err => {
            console.log(err, "err");
            this.isLoading = false;
          });
      } else {
        alert("Max 20");
      }
    },
  },
};
</script>

<style>
.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.quantity__minus,
.quantity__plus {
  display: block;
  width: 70px;
  height: 70px;
  margin: 0;
  background: #dc3545;
  text-decoration: none;
  text-align: center;
  line-height: 70px;
}
.quantity__minus:hover,
.quantity__plus:hover {
  background: #dc3545;
  color: #dc3545;
}
.quantity__minus {
  border-radius: 5px 0 0 5px;
}
.quantity__plus {
  border-radius: 0 5px 5px 0;
}
.quantity__input {
  width: 110px;
  height: 70px;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 2px solid #dc3545;
  border-bottom: 2px solid #dc3545;
  border-left: 1px solid #dc3545;
  border-right: 2px solid #dc3545;
  background: #000;
  color: #fff;
}
.quantity__minus:link,
.quantity__plus:link {
  color: #8184a1;
}
.quantity__minus:visited,
.quantity__plus:visited {
  color: #000;
}
</style>
